import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import BradMain from "../../img/bradMain.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: "#424242",
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
    minHeight: 200,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: 5,
  },
  text: {
    color: "white",
  },
  title: {
    fontFamily: "monospace",
    color: "#76d275",
  },
}));

export default function About() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              ABOUT
            </Typography>
          </Grid>
          <Grid item>
            <ButtonBase className={classes.image}>
              <img className={classes.img} alt="brad peters" src={BradMain} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.text}
                >
                  Brad is a full stack software engineer originally from
                  Atlanta, Georgia. He has spent the last 3 years traveling the
                  world while writing code for various ecommerce platforms. Brad
                  has dedicated his time to exploring the globe while continuing
                  to learn and grow as a software engineer.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
