import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: "#424242",
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
    minHeight: 200,
  },
  icon: {
    color: "#43a047",
    fontSize: 40,
    padding: 5,
  },
  text: {
    marginTop: 5,
    color: "white",
    fontSize: 12,
  },
  title: {
    color: "#76d275",
    fontFamily: "monospace",
  },
}));

export default function Skills() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              SKILLS
            </Typography>
          </Grid>
          <Grid item>
            <i className={`devicon-java-plain colored ${classes.icon}`} />
            <Typography variant={"body1"} className={classes.text}>
              Java
            </Typography>
          </Grid>
          <Grid item>
            <i className={`devicon-react-original colored ${classes.icon}`} />
            <Typography variant={"body1"} className={classes.text}>
              React
            </Typography>
          </Grid>
          <Grid item>
            <i className={`devicon-mysql-plain colored ${classes.icon}`} />
            <Typography variant={"body1"} className={classes.text}>
              MYSQL
            </Typography>
          </Grid>
          <Grid item>
            <i className={`devicon-spring-plain colored ${classes.icon}`} />
            <Typography variant={"body1"} className={classes.text}>
              Spring
            </Typography>
          </Grid>
          <Grid item>
            <i
              className={`devicon-amazonwebservices-original colored ${classes.icon}`}
            />
            <Typography variant={"body1"} className={classes.text}>
              AWS
            </Typography>
          </Grid>
          <Grid item>
            <i className={`devicon-laravel-plain colored ${classes.icon}`} />
            <Typography variant={"body1"} className={classes.text}>
              Laravel
            </Typography>
          </Grid>
          <Grid item>
            <i className={`devicon-python-plain colored ${classes.icon}`} />
            <Typography variant={"body1"} className={classes.text}>
              Python
            </Typography>
          </Grid>
          <Grid item>
            <i className={`devicon-go-plain plain ${classes.icon}`} />
            <Typography variant={"body1"} className={classes.text}>
              Go
            </Typography>
          </Grid>
          <Grid item>
            <i className={`devicon-docker-plain colored ${classes.icon}`} />
            <Typography variant={"body1"} className={classes.text}>
              Docker
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
