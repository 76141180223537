import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { SocialIcon } from "react-social-icons";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: 5,
    top: "auto",
    bottom: 0,
    backgroundColor: "#424242",
  },
  social: {
    margin: 10,
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar>
        <Typography>Get in touch!</Typography>
        <SocialIcon
          className={classes.social}
          url="https://www.linkedin.com/in/brad-peters-366ab5ab/"
        />
        <SocialIcon
          className={classes.social}
          url="https://www.instagram.com/brad_peters89/"
        />
        <SocialIcon
          className={classes.social}
          url="https://github.com/bradxpeters"
        />
      </Toolbar>
    </AppBar>
  );
}
