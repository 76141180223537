import "./App.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Layout from "./components/layout";
import { createTheme, MuiThemeProvider } from "@material-ui/core";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Nunito",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <Header />
          <Footer />
        </header>
        <div
          style={{
            padding: 10,
            backgroundColor: "#282c34",
            minHeight: "100vh",
          }}
        >
          <Layout
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </div>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
